import React from "react"
import Layout from "../../layouts/default"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import PageTitle from "../../components/page-title"
import Stack from "../../components/stack"
import HyperCard from "../../components/hyper-card"

const SonderausstellungIndexPage = () => {
  const data = useStaticQuery(graphql`
    query SonderausstellungenQuery {
      hoelderlinLiebt: file(
        relativePath: { eq: "startseite/hoelderlin-liebt-ausstellung.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 700, height: 700, layout: CONSTRAINED)
        }
      }
      hegelHoelderlin: file(
        relativePath: { eq: "backdrops/hegel-hoelderlin.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 700, height: 700, layout: CONSTRAINED)
        }
      }
      orte: file(relativePath: { eq: "backdrops/orte.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700, height: 700, layout: CONSTRAINED)
        }
      }
      postkarte: file(
        relativePath: { eq: "og_images/feuer-cover.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 700, height: 700, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Digitale Sonderausstellungen"
        description="Unsere digitalen Angebote zu den Sonderausstellungen bereiten auf den Ausstellungsbesuch im Hölderlinturm vor oder bieten die Möglichkeit, das Gesehene noch einmal zu vertiefen."
      />
      <Stack>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
            ]}
          />
          <PageTitle>Sonderausstellungen</PageTitle>
          <Paragraph dropcap={true}>
            Die digitalen Angebote zu den Sonderausstellungen bereiten auf den
            Ausstellungsbesuch im Hölderlinturm vor oder bieten die Möglichkeit,
            das Gesehene noch einmal zu vertiefen. Sie greifen einzelne Themen
            und Elemente der Ausstellungen auf, erweitern sie um wissenswerte
            Hintergrundinformationen, spannende Details und Experteninterviews,
            bieten Möglichkeiten zur Interaktion und laden zur Beschäftigung mit
            Hölderlins Sprache, Leben und Werk ein.
          </Paragraph>
        </Stack>

        <HyperCard
          title="Turm in Flammen!"
          headingElement="h2"
          alt="Karikatur, der Hölderlinturm brennt und eine Gruppe erstaunter Touristen fährt in einem Stocherkahn über den Neckar am Turm vorbei"
          description="In der Nacht zum 15. Dezember 1875 brannte das Haus, in dem Friedrich Hölderlin seine zweite Lebenshälfte verbracht hat, nieder. Erst mit dem Wiederaufbau erhielt es seine berühmte Gestalt und wurde zum vorrangigen Ort der Erinnerung an den Dichter. Durch einen spektakulären Archivfund kann nun erstmals belegt werden, dass das Haus einer Brandstiftung zum Opfer fiel. Die Ausstellung geht diesem Kriminalfall nach."
          image={data.postkarte}
          to="/sonderausstellungen/feuerteufel"
          cta="Zur Digitalen Ausstellung"
        />
        <HyperCard
          title="Hölderlin liebt..."
          headingElement="h2"
          alt="Ein Blick in die Sonderausstellung. Im Raum steht ein runder Tisch. An der Wand sind eine kräftig-bunte Illustration, ein Video in Gebärdensprache und der Ausstellungstitel ›Hölderlin liebt...‹ angebracht."
          description="Der Dichter Friedrich Hölderlin ist oft verliebt: Er liebt die Natur mit ihren hohen Bäumen und weiten Flüssen. Er liebt eine Frau mit dem Namen Susette Gontard. Und vor allem liebt er die Wörter. Das erfahren Sie auch in unserer digitalen Ausstellung. In Einfacher Sprache und Gebärdensprache."
          image={data.hoelderlinLiebt}
          to="/sonderausstellungen/hoelderlin-liebt"
          cta="Zur Digitalen Ausstellung"
        />
        <HyperCard
          title="Idealismusschmiede in der Philosophen-WG"
          headingElement="h2"
          alt="Ein Detail aus der Sonderausstellung ›Idealismusschmiede in der Philosophen-WG‹. An drei Holzleisten sind pastellfarbene Brieftexte von Hegel, Hölderlin und Schelling angebracht."
          description="Hölderlin und Hegel verbrachten 5 gemeinsame Studienjahre in Tübingen und teilten sich zuweilen sogar ein Zimmer. Die Ausstellung den Anfängen der beiden Geistesgrößen nach, zeigt, wie sie sich gegenseitig in ihrem Denken beeinflusst haben und wie sie heute gelesen und betrachtet werden."
          image={data.hegelHoelderlin}
          to="/sonderausstellungen/hegel-hoelderlin"
          cta="Zur Digitalen Ausstellung"
        />
        <HyperCard
          title="Hölderlins Orte"
          headingElement="h2"
          alt="Schwarzweiß-Fotografie des Zwingels, ein schmaler Weg am Hölderlinturm"
          description={`In der Wanderausstellung ›Hölderlins Orte – Fotografien von Barbara
            Klemm‹ treffen Friedrich Hölderlins Verse und
            Landschaftsbeschreibungen auf die Bilder der Fotografin Barbara
            Klemm.`}
          image={data.orte}
          to="/sonderausstellungen/hoelderlins-orte"
          cta="Zur Digitalen Ausstellung"
        />
      </Stack>
    </Layout>
  )
}

export default SonderausstellungIndexPage
